import { t } from '@lingui/macro';
import clsx from 'clsx';
import type { KeenSliderPlugin } from 'keen-slider/react';
import { useKeenSlider } from 'keen-slider/react';
import type { ImageProps } from 'next/image';
import Image from 'next/image';
import { useState } from 'react';
import type { MouseEvent, TouchEvent } from 'react';
import { Shimmer, toBase64, VideoPlayer } from '../../../components';
import type { VideoPlayerProps } from '../../../components';
import { Button } from '../../../components/ui/button/Button';
import 'keen-slider/keen-slider.min.css';

import Arrow from '../../../icons/direction/arrow/arrow.svg';

import styles from './Hero.module.scss';

export type HeroSlideProps = {
  desktopImage?: ImageProps;
  mobileImage?: ImageProps;
  productImage?: ImageProps;
  desktopImageCover?: boolean;
  mobileImageCover?: boolean;
  backgroundColor?: string;
  dotsBackground?: string;
  subtitle?: string;
  title?: string;
  description?: string;
  disclaimer?: string;
  subtitleReverse?: boolean;
  titleReverse?: boolean;
  descriptionReverse?: boolean;
  disclaimerReverse?: boolean;
  buttonLink?: string;
  buttonText?: string;
  newTab?: boolean;
  initialSlide?: boolean;
  currentSlide?: boolean;
  eager?: boolean;
  mobileTextReverse?: boolean;
  single?: boolean;
};

export type VideoHeroProps = {
  videoPlayerProps: VideoPlayerProps;
  backgroundColor?: string;
  dotsBackground?: string;
  subtitle?: string;
  title?: string;
  description?: string;
  disclaimer?: string;
  subtitleReverse?: boolean;
  titleReverse?: boolean;
  descriptionReverse?: boolean;
  disclaimerReverse?: boolean;
  buttonLink?: string;
  buttonText?: string;
  newTab?: boolean;
  initialSlide?: boolean;
  currentSlide?: boolean;
  setSlideVideoPlayingIndexes?: () => void;
  withMargins?: boolean;
};

export type HeroProps = {
  slides: (HeroSlideProps & VideoHeroProps)[];
  eager?: boolean;
};

/* eslint-disable sonarjs/cognitive-complexity */
/**
 * Hero Slide Component.
 * @param props - HeroSlideProps Component props
 */
export const HeroSlide = ({
  desktopImage,
  mobileImage,
  productImage,
  backgroundColor,
  dotsBackground,
  desktopImageCover = false,
  mobileImageCover = false,
  subtitle,
  title,
  description,
  disclaimer,
  subtitleReverse,
  titleReverse,
  descriptionReverse,
  disclaimerReverse,
  buttonLink,
  buttonText,
  newTab,
  initialSlide,
  currentSlide,
  eager,
  mobileTextReverse,
  single,
}: HeroSlideProps) => {
  return (
    <div className={styles.hero__slide} style={{ backgroundColor }}>
      {desktopImage && (
        <Image
          className={clsx(
            styles.hero__image,
            styles['hero__image--desktop'],
            desktopImageCover && styles['hero__image--desktopCover']
          )}
          placeholder="blur"
          blurDataURL={`data:image/svg+xml;base64,${toBase64(
            Shimmer(
              desktopImage.width ? Number(desktopImage.width) : 0,
              desktopImage.height ? Number(desktopImage.height) : 0
            )
          )}`}
          src={desktopImage.src}
          alt={desktopImage.alt}
          title={desktopImage.title || desktopImage.alt}
          fill
          sizes="100vw"
        />
      )}
      {mobileImage && (
        <div className={styles.hero__mobileImageWrapper}>
          <Image
            className={clsx(
              styles.hero__image,
              styles['hero__image--mobile'],
              mobileImageCover && styles['hero__image--mobileCover']
            )}
            placeholder="blur"
            blurDataURL={`data:image/svg+xml;base64,${toBase64(
              Shimmer(
                mobileImage.width ? Number(mobileImage.width) : 0,
                mobileImage.height ? Number(mobileImage.height) : 0
              )
            )}`}
            loading={eager ? 'eager' : 'lazy'}
            src={mobileImage.src}
            alt={mobileImage.alt}
            title={mobileImage.title || mobileImage.alt}
            fill
            priority={eager}
            sizes="100vw"
          />
        </div>
      )}
      <div
        className={clsx(
          styles.hero__slideForeground,
          currentSlide && !initialSlide && styles.hero__animated,
          mobileImageCover && styles['hero__slideForeground--imageCover'],
          single && styles['hero__slideForeground--single']
        )}
      >
        {(subtitle || title) && (
          <hgroup className={styles.hero__contentTextReverseTitle}>
            {subtitle && (
              <p
                className={clsx(
                  styles.hero__subheading,
                  subtitleReverse && styles['hero__text'],
                  mobileTextReverse && styles['hero__text--mobile']
                )}
              >
                {subtitle}
              </p>
            )}
            {title && (
              <h2
                className={clsx(
                  styles.hero__heading,
                  titleReverse && styles['hero__text'],
                  mobileTextReverse && styles['hero__text--mobile']
                )}
                dangerouslySetInnerHTML={{ __html: title }}
              />
            )}
          </hgroup>
        )}
        {description && (
          <p
            className={clsx(
              styles.hero__message,
              descriptionReverse && styles['hero__text'],
              mobileTextReverse && styles['hero__text--mobile']
            )}
          >
            {description}
          </p>
        )}
        {(buttonLink || buttonText) && (
          <Button
            type="button"
            className={styles.hero__button}
            href={buttonLink}
            target={newTab ? '_blank' : undefined}
          >
            {buttonText}
          </Button>
        )}
        {disclaimer && (
          <p
            className={clsx(
              styles.hero__disclaimer,
              'text-utility-utility-small',
              currentSlide && !initialSlide && styles.hero__animated,
              disclaimerReverse && styles['hero__text'],
              mobileTextReverse && styles['hero__text--mobile']
            )}
          >
            {disclaimer}
          </p>
        )}
      </div>
      {dotsBackground && !single && (
        <div
          className={styles.hero__dotsBackground}
          style={{ backgroundColor: dotsBackground }}
        />
      )}
      {productImage && (
        <div className={styles.hero__overlayImageWrapper}>
          <Image
            className={clsx(
              styles.hero__overlayImage,
              currentSlide && !initialSlide && styles.hero__animated
            )}
            placeholder="blur"
            blurDataURL={`data:image/svg+xml;base64,${toBase64(
              Shimmer(
                productImage.width ? Number(productImage.width) : 0,
                productImage.height ? Number(productImage.height) : 0
              )
            )}`}
            src={productImage.src}
            alt={productImage.alt}
            title={productImage.title || productImage.alt}
            fill
            sizes="(min-width: 768px) 310px, 164px"
          />
        </div>
      )}
    </div>
  );
};

/**
 * Video Hero Component.
 * @param props - VideoHeroProps Component props
 */
export const VideoHeroSlide = ({
  videoPlayerProps,
  backgroundColor,
  subtitle,
  title,
  description,
  subtitleReverse,
  titleReverse,
  descriptionReverse,
  buttonLink,
  buttonText,
  newTab,
  initialSlide,
  currentSlide,
  setSlideVideoPlayingIndexes,
  withMargins,
  dotsBackground,
}: VideoHeroProps) => {
  const [videoPlaying, setVideoPlaying] = useState(false);

  const onVideoPlay = () => {
    setVideoPlaying(true);
    setSlideVideoPlayingIndexes && setSlideVideoPlayingIndexes();
  };

  return (
    <div
      className={clsx(styles.hero__slide, {
        [styles.withMargins]: withMargins,
      })}
      style={{ backgroundColor }}
    >
      <div className={clsx(styles.hero__video)}>
        <VideoPlayer
          {...{
            ...videoPlayerProps,
            onPlay: onVideoPlay,
            className: styles.hero__videoButton,
            coverPoster: true,
          }}
        />
      </div>

      <div
        className={clsx(
          styles.hero__slideForeground,
          currentSlide && !initialSlide && styles.hero__animated,
          videoPlaying && styles.hero__hide
        )}
      >
        {(subtitle || title) && (
          <hgroup>
            {subtitle && (
              <p
                className={clsx(
                  styles.hero__subheading,
                  subtitleReverse && styles['hero__text']
                )}
              >
                {subtitle}
              </p>
            )}
            {title && (
              <h2
                className={clsx(
                  styles.hero__heading,
                  titleReverse && styles['hero__text']
                )}
                dangerouslySetInnerHTML={{ __html: title }}
              />
            )}
          </hgroup>
        )}
        {description && (
          <p
            className={clsx(
              styles.hero__message,
              descriptionReverse && styles['hero__text']
            )}
          >
            {description}
          </p>
        )}
        {(buttonLink || buttonText) && (
          <Button
            type="button"
            className={styles.hero__button}
            href={buttonLink}
            target={newTab ? '_blank' : undefined}
          >
            {buttonText}
          </Button>
        )}
      </div>
      {dotsBackground && (
        <div
          className={styles.hero__dotsBackground}
          style={{ backgroundColor: dotsBackground }}
        />
      )}
    </div>
  );
};

const ResizePlugin: KeenSliderPlugin = (slider) => {
  const observer = new ResizeObserver(function () {
    slider.container.style.height =
      (slider.slides[slider.track.details.rel].firstElementChild
        ?.clientHeight ?? 0) + 'px';
  });
  slider.on('created', () => {
    observer.observe(slider.container);
  });
  slider.on('destroyed', () => {
    observer.unobserve(slider.container);
  });
};

const AdaptiveHeight: KeenSliderPlugin = (slider) => {
  function updateHeight() {
    slider.container.style.height =
      (slider.slides[slider.track.details.rel].firstElementChild
        ?.clientHeight ?? 0) + 'px';
  }
  slider.on('created', updateHeight);
  slider.on('slideChanged', updateHeight);
};

export const Hero = ({ slides, eager }: HeroProps) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [touched, setTouched] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [videoSlide, setVideoSlide] = useState(false);
  const [slideVideoPlayingIndexes, setSlideVideoPlayingIndexes] = useState<
    Array<number>
  >([]);
  const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>(
    {
      initial: 0,
      loop: true,
      slideChanged(slider) {
        const slide = slider.slides[slider.track.details.rel];
        setVideoSlide(slide.hasAttribute('data-video-slide'));

        setTouched(true);
        setCurrentSlide(slider.track.details.rel);
      },
      created() {
        setLoaded(true);
      },
    },
    [AdaptiveHeight, ResizePlugin]
  );

  return (
    <>
      <section
        className={clsx(
          styles.hero,
          styles.hero__slider,
          !loaded && styles['hero__slider--loading']
        )}
      >
        <div ref={sliderRef} className="keen-slider">
          {slides.map((slide, index) => {
            if (slide.videoPlayerProps) {
              return (
                <div
                  key={index}
                  className={clsx(
                    `number-slide${index + 1}`,
                    'keen-slider__slide'
                  )}
                  data-video-slide
                >
                  <VideoHeroSlide
                    {...slide}
                    initialSlide={index === 0 && !touched}
                    currentSlide={currentSlide === index}
                    setSlideVideoPlayingIndexes={() =>
                      setSlideVideoPlayingIndexes([
                        ...slideVideoPlayingIndexes,
                        index,
                      ])
                    }
                  />
                </div>
              );
            }

            return (
              <div
                key={index}
                className={clsx(
                  `number-slide${index + 1}`,
                  'keen-slider__slide'
                )}
              >
                <HeroSlide
                  {...slide}
                  initialSlide={index === 0 && !touched}
                  currentSlide={currentSlide === index}
                  eager={eager}
                />
              </div>
            );
          })}
        </div>
        {loaded && slides.length > 1 && instanceRef.current && (
          <>
            <ArrowButton
              className={clsx(
                styles.hero__navButton,
                styles['hero__navButton--prev']
              )}
              onClick={(e) => {
                e.stopPropagation();
                instanceRef.current?.prev();
              }}
              aria-label="Hero previous button"
              left={true}
            />

            <ArrowButton
              className={clsx(
                styles.hero__navButton,
                styles['hero__navButton--next']
              )}
              onClick={(e) => {
                e.stopPropagation();
                instanceRef.current?.next();
              }}
              aria-label="Hero next button"
            />

            <div className={styles.hero__dots}>
              <div className="container">
                {[
                  ...Array(
                    instanceRef.current.track.details.slides.length
                  ).keys(),
                ].map((idx) => {
                  return (
                    <button
                      key={idx}
                      onClick={(e) => {
                        e.stopPropagation();
                        instanceRef.current?.moveToIdx(idx);
                      }}
                      className={styles.hero__slidePipButton}
                      aria-label={t({
                        id: 'hero.pagination.button',
                        message: `Go to slide ${idx + 1}`,
                      })}
                    >
                      <span
                        className={clsx(
                          styles.hero__dot,
                          currentSlide === idx && styles['hero__dot--active']
                        )}
                      />
                    </button>
                  );
                })}
              </div>
            </div>
          </>
        )}
      </section>
    </>
  );
};

const ArrowButton = ({
  className,
  onClick,
  left,
}: {
  className?: string;
  left?: boolean;
  onClick: (
    e: MouseEvent<HTMLButtonElement> | TouchEvent<HTMLButtonElement>
  ) => void;
}) => {
  const arrowAriaLabel = left
    ? t({
        id: 'slider.previous',
        message: 'Previous slide',
      })
    : t({
        id: 'slider.next',
        message: 'Next slide',
      });

  return (
    <button
      className={clsx(className, styles.hero__arrow)}
      onClick={onClick}
      aria-label={arrowAriaLabel}
    >
      <Arrow />
    </button>
  );
};
